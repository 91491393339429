// any new route introduced in the app should be a part of this config file
// `routePath`: route that will render a given component
// `translationKey`: key that points to the given page related translations within `en-us.json`
// it is expected that the translation object of a particular page should definitely
// contain a property `page_title`, which will contain the page's title

import { CONST } from '.'
import { getPaths } from '../pages/Security/utils'

const ACTIVITY_CONSTANTS = {
  GET_ORG_SUMMARY: 'getOrgSummary',
  GET_ORG_BUNDLES: 'getAllOrgBundles',
  GET_CLOUD_TYPES: 'getAllCloudTypes',
  GET_ACCOUNTS: 'getAccounts',
  GET_PRODUCTS: 'getAllProducts',
  GET_LOGIN_METHODS: 'getAllowedLoginMethods',
  GET_CURRENT_USER: 'getCurrentUserDetails'
}
const {
  GET_ACCOUNTS,
  GET_CLOUD_TYPES,
  GET_CURRENT_USER,
  GET_LOGIN_METHODS,
  GET_ORG_BUNDLES,
  GET_ORG_SUMMARY,
  GET_PRODUCTS
} = ACTIVITY_CONSTANTS

// TODO: Following list to contain all route level config information
const ORG_DETAILS_IGNORE_APIS = [
  GET_ORG_SUMMARY,
  GET_ORG_BUNDLES,
  GET_CLOUD_TYPES,
  GET_ACCOUNTS,
  GET_PRODUCTS
]

const ORG_DETAILS_EXCEPT_SUMMARY_IGNORE_LIST = ORG_DETAILS_IGNORE_APIS.filter(
  (api) => api !== GET_ORG_SUMMARY
)

const AUTH_DETAILS_IGNORE_APIS = [GET_LOGIN_METHODS]
const ROUTE_CONFIG = [
  {
    routePath: CONST.LOGIN_PATHNAME,
    translationKey: 'common_login',
    mandatoryMetaAPIToIgnore: ORG_DETAILS_IGNORE_APIS
  },
  {
    routePath: CONST.RE_AUTH_PATHNAME,
    translationKey: 'common_login',
    mandatoryMetaAPIToIgnore: [GET_CURRENT_USER, ...ORG_DETAILS_IGNORE_APIS]
  },
  {
    routePath: '/session-exists',
    translationKey: 'common_login',
    mandatoryMetaAPIToIgnore: ORG_DETAILS_IGNORE_APIS
  },
  {
    routePath: '/login/update-password',
    translationKey: 'common_login.reset_password',
    mandatoryMetaAPIToIgnore: ORG_DETAILS_IGNORE_APIS
  },
  {
    routePath: '/',
    translationKey: 'dashboard'
  },
  {
    routePath: '/activate-day-pass/consent/bundle/:bundleId',
    translationKey: 'dashboard'
  },
  {
    routePath: '/profile',
    translationKey: 'my_profile'
  },
  {
    routePath: '/profile/change-password',
    translationKey: 'my_profile'
  },
  {
    routePath: '/profile/manage-thirdparty-apps',
    translationKey: 'my_profile'
  },
  {
    routePath: CONST.SECURITY_AGENTS_PATHNAME,
    translationKey: 'security'
  },
  {
    routePath: '/security/agents/session-management',
    translationKey: 'security.settings.session_management'
  },
  {
    routePath: '/security/agents/session-management/manage-sessions',
    translationKey: 'security.settings.manage_active_sessions'
  },
  {
    routePath: getPaths.editPermissions(),
    translationKey: 'security.settings.edit_field_permissions'
  },
  {
    routePath: '/organization',
    translationKey: 'organisation'
  },
  {
    routePath: '/forgot-password',
    translationKey: 'common_login.forgot_password',
    mandatoryMetaAPIToIgnore: [GET_CURRENT_USER, ...ORG_DETAILS_IGNORE_APIS]
  },
  {
    routePath: '/magiclink/request',
    translationKey: 'common_login.magiclink_login',
    mandatoryMetaAPIToIgnore: ORG_DETAILS_IGNORE_APIS
  },
  {
    routePath: '/reset-password/:hash',
    translationKey: 'common_login.reset_password',
    mandatoryMetaAPIToIgnore: [...ORG_DETAILS_IGNORE_APIS, ...AUTH_DETAILS_IGNORE_APIS]
  },
  {
    routePath: '/invite/:hash',
    translationKey: 'user_activation',
    mandatoryMetaAPIToIgnore: [...ORG_DETAILS_IGNORE_APIS, ...AUTH_DETAILS_IGNORE_APIS]
  },
  {
    routePath: '/magiclink/:hash/login',
    translationKey: 'common_login.magiclink_login',
    mandatoryMetaAPIToIgnore: [...ORG_DETAILS_IGNORE_APIS, ...AUTH_DETAILS_IGNORE_APIS]
  },
  {
    routePath: '/transfers/approval',
    translationKey: 'account_transfer',
    mandatoryMetaAPIToIgnore: ORG_DETAILS_IGNORE_APIS
  },
  {
    routePath: '/mfa/setup',
    translationKey: 'my_profile.multi_factor_authentication',
    mandatoryMetaAPIToIgnore: [...ORG_DETAILS_IGNORE_APIS, ...AUTH_DETAILS_IGNORE_APIS]
  },
  {
    routePath: '/subscriptions',
    translationKey: 'bills'
  },
  {
    routePath: '/subscriptions/:id',
    translationKey: 'bills'
  },
  {
    routePath: '/subscriptions/:id/checkout',
    translationKey: 'bills.checkout'
  },
  {
    routePath: '/subscriptions/:id/updatepayment',
    translationKey: 'bills.payment_update'
  },
  {
    routePath: '/set-password/:hash',
    translationKey: 'set_password',
    mandatoryMetaAPIToIgnore: [...ORG_DETAILS_IGNORE_APIS, ...AUTH_DETAILS_IGNORE_APIS]
  },
  {
    routePath: '/unlock/:hash',
    translationKey: 'unlock',
    mandatoryMetaAPIToIgnore: ORG_DETAILS_IGNORE_APIS
  },
  {
    routePath: '/users-groups/users/',
    translationKey: 'user_management'
  },
  {
    routePath: '/users-groups/users/:uuid',
    translationKey: 'user_management'
  },
  {
    routePath: '/users-groups/customroles',
    translationKey: 'user_management'
  },
  {
    routePath: '/users-groups/customroles/:id',
    translationKey: 'user_management'
  },
  {
    routePath: '/users-groups/users/:uuid/accounts',
    translationKey: 'user_management'
  },
  {
    routePath: '/users-groups/users/:uuid/profile',
    translationKey: 'user_management'
  },
  {
    routePath: '/users-groups/users/:uuid/security',
    translationKey: 'user_management'
  },
  {
    routePath: '/login/mfa/auth-code',
    translationKey: 'my_profile.multi_factor_authentication.authenticator_code_page',
    mandatoryMetaAPIToIgnore: ORG_DETAILS_IGNORE_APIS
  },
  {
    routePath: '/login/mfa/recovery-code',
    translationKey: 'my_profile.multi_factor_authentication.recovery_code_page',
    mandatoryMetaAPIToIgnore: ORG_DETAILS_IGNORE_APIS
  },
  {
    routePath: '/audit-logs',
    translationKey: 'audit_logs'
  },
  {
    routePath: '/audit-logs/billing',
    translationKey: 'audit_logs'
  },
  {
    routePath: '/users-groups/users/:uuid/usergroups',
    translationKey: 'user_management'
  },
  {
    routePath: '/users-groups/groups/',
    translationKey: 'user_management'
  },
  {
    routePath: '/users-groups/groups/:groupId',
    translationKey: 'user_management'
  },
  {
    routePath: '/users-groups/groups/:groupId/members',
    translationKey: 'user_management'
  },
  {
    routePath: '/login-consent',
    translationKey: 'common_login',
    mandatoryMetaAPIToIgnore: ORG_DETAILS_EXCEPT_SUMMARY_IGNORE_LIST
  },
  {
    routePath: '/authorize/error',
    translationKey: ''
  },
  {
    routePath: '/authorize/consent',
    translationKey: 'API_Oauth'
  },
  {
    routePath: '/authorize/account-select',
    translationKey: 'API_Oauth'
  }
]

export default ROUTE_CONFIG
export { ORG_DETAILS_IGNORE_APIS, AUTH_DETAILS_IGNORE_APIS, ACTIVITY_CONSTANTS }
